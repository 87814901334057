import React from 'react';
import '../assets/css/CandidateActions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain,  } from '@fortawesome/free-solid-svg-icons';
import { faThinkPeaks } from '@fortawesome/free-brands-svg-icons';

const ActionItem = ({ icon, label, link }) => {
    return (
      <a href={link} className="action-item">
        <div className="icon-circle">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
        <span className="label">{label}</span>
      </a>
    );
  };
  
  const MentalSectors = () => {
    return (
      <div className="actions-container">
        <ActionItem icon={faBrain} label="NHS MENTAL HEALTHCARE"/>
        <ActionItem icon={faThinkPeaks} label="PRIVATE MENTAL HEALTHCARE" />
      </div>
    );
  };
  
  export default MentalSectors;
