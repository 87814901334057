import React from 'react';
import '../assets/css/CandidateActions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload, faMobileAlt, faPeopleArrows, faPersonChalkboard, faArrowsDownToPeople } from '@fortawesome/free-solid-svg-icons';

const ActionItem = ({ icon, label, link }) => {
    return (
      <a href={link} className="action-item">
        <div className="icon-circle">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
        <span className="label">{label}</span>
      </a>
    );
  };
  
  const CandidateActions = () => {
    return (
      <div className="actions-container">
        <ActionItem icon={faUpload} label="QUICK CV UPLOAD"/>
        <ActionItem icon={faMobileAlt} label="REQUEST A CALLBACK"  />
        <ActionItem icon={faPeopleArrows} label="SCHEDULE AN INTERVIEW"/>
        <ActionItem icon={faArrowsDownToPeople} label="REFERRAL SCHEME" />
        <ActionItem icon={faPersonChalkboard} label="TRAINING SELECTION"/>
        {/* Repeat for other items, replacing the icon, label, and link as necessary */}
      </div>
    );
  };
  
  export default CandidateActions;
