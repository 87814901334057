import React from 'react';
import SectionTitle from "../UI/SectionTitle";
import BlogItem from "./blogItem";
import Blogs from '../../data/Blog/blog';
import BlogsSZ from '../../data/Blog/blogCZ';
import CandidateActions from "../../components/CandidateActions";
import SignupProcess from '../../components/SignupProcess';
import NhsSectors from '../../components/NhsSectors';
import MentalSectors from '../../components/MentalHealthSectors';
import PrivateCare from '../../components/PrivateCare';

function Blog({ numberOfPage }) {
    const paragraphPrivateCare = "Private Care <br>";
    const paragraph = "Live Healthcare Positions <br>";
    let vaueHealthCareparagraph = numberOfPage === 3 ? paragraphPrivateCare : "";

    return (
        <div className="blog-area-wrapper sm-top">
            <div className="container">
            {numberOfPage !== 3 && (
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>What We Do for Candidates</h2>
                        </div>
                    </div>
                )}

                {numberOfPage !== 3 && <CandidateActions />}

                {numberOfPage !== 3 && (
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>Our Sign Up Process</h2>
                        </div>
                    </div>
                )}

                {numberOfPage !== 3 && <SignupProcess />}

                {numberOfPage === 3 && (
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>NHS SECTORS</h2>
                        </div>
                    </div>
                )}
                {numberOfPage === 3 && <NhsSectors />}

                {numberOfPage === 3 && (
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>MENTAL HEALTH SECTORS</h2>
                        </div>
                    </div>
                )}
                {numberOfPage === 3 && <MentalSectors/>}

                {numberOfPage === 3 && (
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>PRIVATE CARE</h2>
                        </div>
                    </div>
                )}
                {numberOfPage === 3 && <PrivateCare/>}
            </div>
        </div>
    );
}

export default Blog;
