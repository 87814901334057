import React from 'react';
import FunfactItem from "./funfactItem";

import Funfacts from '../../data/Funfact/funfact'

function Funfact({ ribbonColor }) {
    const ribbonStyle = {
        backgroundColor: ribbonColor || "#2589ff", // Default color is blue if not specified
    };
    return (
        <div className="fun-fact-area sm-top parallax" style={ribbonStyle}>
            <div className="container">
                <div className="row mtn-10">
                    {/* {
                        Funfacts.map(funfact => (
                            <FunfactItem key={funfact.id} counterNumber={funfact.counterNumber} counterText={funfact.counterText} />
                        ))
                    } */}
                    {/* <FunfactItem counterText={"Compassionate, Integrity, Dependability, confidence, openness, Understanding, partnership,Compliance"} /> */}
                    <p style={{
                        color: "#f8f9fc",
                        fontSize: "20px", fontWeight: "600", margin: "auto"
                    }}>Compassionate - Integrity - Dependability - Confidence - Openness - Understanding - Partnership - Compliance</p>
                </div>
            </div>
        </div >
    );
}

export default Funfact;