// SpecialismSection.js
import React from 'react';
import '../assets/css/SpecialismSection.css'; 
const specialisms = [
  {
    title: 'Permanent Recruitment',
    description: 'Connecting businesses with lasting talent for sustained success.',
  },
  {
    title: 'Temporary Recruitement',
    description: 'Fulfilling short-term staffing needs with skilled professionals.',
  },
  {
    title: 'Trustworthy Service',
    description: 'Consistently dependable solutions to exceed your expectations.',
  },
  // ... Add more specialisms as needed
];

const SpecialismSectionTwo = () => {
  return (
    <div className="specialism-section">
      <h2>WE PROVIDE:</h2>
      <div className="specialisms-container">
        {specialisms.map((specialism, index) => (
          <div key={index} className="specialism">
            <h3>{specialism.title}</h3>
            <p>{specialism.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialismSectionTwo;
