import React from 'react';
import '../assets/css/SpecialismSection.css'; 

const specialisms = [
  {
    title: "Private Hospital Care",
    description: 'Meeting the staffing needs of our clients is our top priority; ensuring high-quality care and support for residents and patients.'
  },
  {
    title: 'Care Homes',
    description: 'Meeting the staffing needs of our clients is our top priority; ensuring high-quality care and support for residents and patients.',
  },
  {
    title: 'Travel Care',
    description: 'Meeting the staffing needs of our clients is our top priority; ensuring high-quality care and support for residents and patients.',
  },
  {
    title: 'Foster Care Homes',
    description: 'Meeting the staffing needs of our clients is our top priority; ensuring high-quality care and support for residents and patients.',
  },
  {
    title: 'Clinics',
    description: 'Meeting the staffing needs of our clients is our top priority; ensuring high-quality care and support for residents and patients.',
  }
];

const PrivateCare = () => {
  return (
    <div className="specialism-section">
      <div className="specialisms-container" style={{ flexWrap: 'wrap' }}>
        {specialisms.map((specialism, index) => (
          <div key={index} className="specialism">
            <h3>{specialism.title}</h3>
            <p>{specialism.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivateCare;
