import React from 'react';
import '../assets/css/CandidateActions.css'; // Adjust the CSS path as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faClipboardCheck, // for Pre-Screen
  faUserPlus, // for Register
  faComments, // for Consult
  faUserCheck // for Placement
} from '@fortawesome/free-solid-svg-icons';

const ActionItem = ({ icon, label, link }) => {
    return (
      <a href={link} className="action-item">
        <div className="icon-circle">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
        <span className="label">{label}</span>
      </a>
    );
  };

const SignupProcess = () => {
  return (
    <div className="signup-process-container">
      <div className="actions-container">
        <ActionItem icon={faClipboardCheck} label="PRE-SCREEN" />
        <ActionItem icon={faUserPlus} label="REGISTER"/>
        <ActionItem icon={faComments} label="CONSULT"/>
        <ActionItem icon={faUserCheck} label="PLACEMENT" />
        <div className="buttons-container">
        <button className="action-button upload-cv" >UPLOAD YOUR CV</button>
        <button className="action-button refer-friend" >REFER A FRIEND</button>
      </div>
      </div>
    </div>
  );
};

export default SignupProcess;