import React from 'react';
import parse from "html-react-parser";
import compliance from "../../data/Compliance/Compliance";
import { Link } from "react-router-dom";

const Compliance = () => {
    return (
        <div className="home-two-about-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 d-lg-none">
                    </div>


                    <div className="about-content about-content--2">
                        <h6>{compliance.title}</h6>
                        <h2>{parse(compliance.heading)}</h2>
                        {/* <span className="about-since">{volunteering.since}</span> */}
                        <p>{parse(compliance.text)}</p>
                        {/* <Link to={`${process.env.PUBLIC_URL + aboutData.btnLink}`} className="btn-about">{aboutData.btnText} <i className="fa fa-angle-double-right" /></Link> */}
                        <div className="share-article text-center" style={{ marginTop: "6%" }} >
                            <div className="mt-sm-25" >
                                <Link to="/contact" className="btn-outline" style={{ width: '50%' }}>Apply</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Compliance;
