import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from '../../assets/img/logo-dark.png'
import socialNetworks from '../../data/SocialNetworks/socials'

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                {/* <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img src={Logo} alt="Logo" />
                                </Link> */}
                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} Phantom Global Agency Ltd. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Information">
                            <List classes="widget-list">
                                <LI><Link to={`${process.env.PUBLIC_URL + "/about"}`}>Our company</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/contact"}`}>Contact us</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our services</Link></LI>
                                {/* <LI><Link to={`${process.env.PUBLIC_URL + "/"}`}>Careers</Link></LI> */}
                            </List>
                        </Widget>
                    </div>

                    {/* <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <LI><a href="https://facebook.com/codinglablife.co.uk/" target="_blank" rel="noopener noreferrer">Facebook</a></LI> */}
                    {/* <LI><a href="https://twitter.com/hasthemes/" target={'_blank'}>Twitter</a></LI>
                                <LI><a href="https://dribbble.com/hastech/" target={'_blank'}>Dribbble</a></LI> */}
                    {/* <LI><a href="https://www.instagram.com/codinglablife/" target={'_blank'}>Instagram</a></LI>
                            </List>
                        </Widget>
                    </div> */}

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                Web: phantomglobal.co.uk <br />
                                Email: info@phantomglobal.co.uk
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;