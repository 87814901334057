import React from 'react';
import { useHistory } from 'react-router-dom';
import '../assets/css/Landing.css';

const landings = [
  {
    title: 'HEALTHCARE',
    description: 'Providing compassionate and qualified top-notch healthcare recruitment choice.',
    className: 'healthcare',
    path: '/healthcare'
  },
  {
    title: 'LOGISTICS',
    description: 'Top-tier recruitment agency, providing opportunities in Driving, Warehousing, and Logistics roles across the UK',
    className: 'logistics',
    path: '/logistics'
  },
  {
    title: 'HOMECARE',
    description: 'COMING SOON...',
    className: 'homecare'
  },
  {
    title: 'CONSTRUCTION',
    description: 'COMING SOON...',
    className: 'construction'
  },
  {
    title: 'HOSPITALITY',
    description: 'COMING SOON...',
    className: 'hospitality'
  },
  {
    title: 'EDUCATION',
    description: 'COMING SOON...',
    className: 'education'
  }
];

const LandingGrid = () => {
  const history = useHistory();

  const handleCardClick = (path) => {
    if (path) {
      history.push(path);
    }
  };

  return (
    <div className="landing-section">
      <h2>WELCOME TO PHANTOM GLOBAL</h2>
      <div className="landings-container">
        {landings.map((landing, index) => (
          <div
            key={index}
            className={`landing ${landing.className}`}
            onClick={() => handleCardClick(landing.path)}
          >
            <div className="landing-inner">
              <div className="landing-front">
                <h3>{landing.title}</h3>
              </div>
              <div className="landing-back">
                <p>{landing.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingGrid;
