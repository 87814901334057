import React, {Fragment} from 'react';

import Slider from '../components/Slider/home-two'
import About from '../components/About/home-two'
import Services from '../components/Services'
import Features from '../components/Features'
import Testimonial from "../components/Testimonials/home-two";
import Team from "../components/Team/home-two";
import Blog from "../components/Blog";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import HeaderTwo from '../components/Header/indextwo';
import AboutTwo from '../components/About/about-two';
import FooterTwo from '../components/Footer/indextwo';
import CallToActionTwo from '../components/CallToAction/indextwo';
import MobileMenuTwo from '../components/MobileMenu/indextwo';
import SpecialismSectionTwo from '../components/SpecialismTwo';

const HomeTwo = () => {
    return (
        <Fragment>
            <HeaderTwo/>
            <Slider/>
            <AboutTwo/>
            <Features classes="sp-top"/>
            <SpecialismSectionTwo/>
            <Funfact ribbonColor="#ffa500" />
            <CallToActionTwo/>
            <FooterTwo/>
            <LoginRegister/>
            <MobileMenuTwo/>
        </Fragment>
    );
};

export default HomeTwo;