import React from 'react';
import {Link} from "react-router-dom";
import navbarData from "../../../data/Navbar/navbartwo";
import PGTimesheet from './PG-Timesheet.pdf';


function NavbarItemTwo(props) {
    const handleDownload = (e, file) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.href = file;
        link.download = file.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return(
        navbarData.map(item => (
            <li key={item.id}
                className={item.subMenu || item.megaMenu ? 'has-submenu' : ''}
            >
                {item.download ? (
                    <a href={PGTimesheet} onClick={(e) => handleDownload(e, PGTimesheet)}>{item.title}</a>
                ) : (
                    <Link to={`${process.env.PUBLIC_URL + item.link}`}>{item.title}</Link>
                )}
                {(() => {
                    if(item.subMenu){
                        return(
                            <ul className="submenu-nav">
                                {
                                    item.subMenu.map((subItem,index)=>{
                                        return <li key={index}><Link to={`${process.env.PUBLIC_URL + subItem.link}`}>{subItem.title}</Link></li>
                                    })
                                }
                            </ul>
                        )
                    }

                    if(item.megaMenu){
                        return(
                            <ul className="submenu-nav submenu-nav-mega">
                                {
                                    item.megaMenu.map((megaItem, indx)=>(
                                        <li key={indx} className="mega-menu-item"><Link to={megaItem.link}>{megaItem.title}</Link>
                                            <ul>
                                                {
                                                    megaItem.lists.map((listItem,idx)=>{
                                                        return <li key={idx}><Link to={`${process.env.PUBLIC_URL + listItem.link}`}>{listItem.title}</Link></li>
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    ))
                                }
                            </ul>
                        )
                    }
                })()}
            </li>
        ))
    )
}

export default NavbarItemTwo