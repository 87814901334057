import React, { Fragment } from 'react';

import Header from '../components/Header'
import Slider from '../components/Slider/home-one'
import About from '../components/About/home-one'
import Features from '../components/Features'
import Services from '../components/Services'
import Team from "../components/Team/home-one";
import Blog from "../components/Blog";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import TestimonialSection from "../components/TestimonialsSection";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials/home-one";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
// import JobMap from "../components/Map/InteractiveUKMap";
import Map from "../components/Map/Map";

const HomeOne = () => {
    const jobs = [
        { regionId: 'E15000007', id: 1, title: 'CAHMS (Band 5 & 6)', regionName: 'London' },
        { regionId: 'E15000005', id: 2, title: 'Childrens Support Worker', regionName: 'West Midlands' },
        { regionId: 'E15000002', id: 3, title: 'General Practitioner', regionName: 'North West' },
        { regionId: 'E15000001', id: 4, title: 'General Surgery Doctor', regionName: 'North East' },
        { regionId: 'E15000009', id: 5, title: 'General Medicine Doctor', regionName: 'South West' },
        { regionId: 'E15000002', id: 6, title: 'Health Care Assistant', regionName: 'North West' },
        { regionId: 'E15000001', id: 7, title: 'Locum GP', regionName: 'North East' },
        { regionId: 'E15000003', id: 8, title: 'Paramedic', regionName: 'Yorkshire and The Humber' },
        { regionId: 'E15000004', id: 9, title: 'Physiotherapist (Band 6)', regionName: 'East Midlands' },
        { regionId: 'E15000006', id: 10, title: 'Registered A&E Nurse (Band 5 & 6)', regionName: 'Eastern' },
        { regionId: 'E15000004', id: 11, title: 'Registered General Nurse (Band 5 & 6)', regionName: 'East Midlands' },
        { regionId: 'E15000008', id: 12, title: 'Registered ITU Nurses (Band 5 & 6)', regionName: 'South East' },
        { regionId: 'E15000006', id: 13, title: 'Registered Mental Health Nurse (Band 5 & 6)', regionName: 'Eastern' },
        { regionId: 'E15000009', id: 14, title: 'Registered Midwifery Nurse (Band 5 & 6)', regionName: 'South West' },
        { regionId: 'E15000007', id: 15, title: 'Registered NICU & PICU Nurses (Band 5 & 6)', regionName: 'London' },
        { regionId: 'E15000003', id: 16, title: 'Registered Paediatric Nurses (Band 5 & 6)', regionName: 'Yorkshire and The Humber' },
        { regionId: 'E15000004', id: 17, title: 'Support Worker', regionName: 'East Midlands' },

        //////////////////////////////////////////////////////////////////////////////////////////////

        { regionId: 'E15000005', id: 1, title: 'CAHMS (Band 5 & 6)', regionName: 'West Midlands' },
        { regionId: 'E15000007', id: 2, title: 'Childrens Support Worker', regionName: 'London' },
        { regionId: 'E15000001', id: 3, title: 'General Practitioner', regionName: 'North East' },
        { regionId: 'E15000002', id: 4, title: 'General Surgery Doctor', regionName: 'North West' },
        { regionId: 'E15000002', id: 5, title: 'General Medicine Doctor', regionName: 'North West' },
        { regionId: 'E15000009', id: 6, title: 'Health Care Assistant', regionName: 'South West' },
        { regionId: 'E15000003', id: 7, title: 'Locum GP', regionName: 'Yorkshire and The Humber' },
        { regionId: 'E15000001', id: 8, title: 'Paramedic', regionName: 'North East' },
        { regionId: 'E15000006', id: 9, title: 'Physiotherapist (Band 6)', regionName: 'Eastern' },
        { regionId: 'E15000004', id: 10, title: 'Registered A&E Nurse (Band 5 & 6)', regionName: 'East Midlands' },
        { regionId: 'E15000008', id: 11, title: 'Registered General Nurse (Band 5 & 6)', regionName: 'South East' },
        { regionId: 'E15000004', id: 12, title: 'Registered ITU Nurses (Band 5 & 6)', regionName: 'East Midlands' },
        { regionId: 'E15000009', id: 13, title: 'Registered Mental Health Nurse (Band 5 & 6)', regionName: 'South West' },
        { regionId: 'E15000006', id: 14, title: 'Registered Midwifery Nurse (Band 5 & 6)', regionName: 'Eastern' },
        { regionId: 'E15000003', id: 15, title: 'Registered NICU & PICU Nurses (Band 5 & 6)', regionName: 'Yorkshire and The Humber' },
        { regionId: 'E15000007', id: 16, title: 'Registered Paediatric Nurses (Band 5 & 6)', regionName: 'London' },
        { regionId: 'E15000007', id: 17, title: 'Support Worker', regionName: 'London' },
        { regionId: 'E15000007', id: 3, title: 'General Practitioner', regionName: 'London' },
        { regionId: 'E15000007', id: 4, title: 'General Surgery Doctor', regionName: 'London' },
        { regionId: 'E15000007', id: 5, title: 'General Medicine Doctor', regionName: 'London' },
        { regionId: 'E15000008', id: 1, title: 'CAHMS (Band 5 & 6)', regionName: 'South East' },
        { regionId: 'E15000008', id: 2, title: 'Childrens Support Worker', regionName: 'South East' },
        { regionId: 'E15000008', id: 3, title: 'General Practitioner', regionName: 'South East' },
        { regionId: 'E15000004', id: 11, title: 'Registered General Nurse (Band 5 & 6)', regionName: 'West Midlands' },
        { regionId: 'E15000008', id: 12, title: 'Registered ITU Nurses (Band 5 & 6)', regionName: 'West Midlands' },
        { regionId: 'E15000005', id: 13, title: 'Registered Mental Health Nurse (Band 5 & 6)', regionName: 'West Midlands' },
        { regionId: 'E15000005', id: 14, title: 'Registered Midwifery Nurse (Band 5 & 6)', regionName: 'West Midlands' },
        { regionId: 'E15000005', id: 15, title: 'Registered NICU & PICU Nurses (Band 5 & 6)', regionName: 'West Midlands' },
        { regionId: 'E15000005', id: 16, title: 'Registered Paediatric Nurses (Band 5 & 6)', regionName: 'West Midlands' },
        { regionId: 'E15000005', id: 17, title: 'Support Worker', regionName: 'West Midlands' },
    
        
    ];
    
    return (
        <Fragment>
            <Header />
            <Slider />
            <About />
            <Features classes="sp-top" />
            <Services classes="sm-top-wt" />
            {/* <JobMap jobs={jobs} /> */}
            <Map jobs={jobs}/>

            <Testimonials />
            {/* <Team/> */}
            {/* <Blog /> */}
            {/* <BrandLogo/> */}
            <TestimonialSection />
            <Funfact ribbonColor="#2589ff" />
            <CallToAction />
            <Footer />
            <MobileMenu />
            <LoginRegister />
        </Fragment>
    );
};

export default HomeOne;