import React, { Fragment } from 'react';
import ContactPage from "../templates/Contact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import HeaderTwo from '../components/Header/indextwo';
import Funfact from '../components/Funfact';
import MobileMenuTwo from '../components/MobileMenu/indextwo';
import CallToActionTwo from '../components/CallToAction/indextwo';
import FooterTwo from '../components/Footer/indextwo';


const ContactTwo = () => {
    return (
        <Fragment>
            <HeaderTwo />
            <ContactPage customBackgroundColor="#ffa500" buttonColor = "#ffa500" />
            <Funfact ribbonColor="#ffa500" />
            <CallToActionTwo/>
            <FooterTwo/>
            <LoginRegister />
            <MobileMenuTwo />
        </Fragment>
    );
};

export default ContactTwo;