import React, { useState, useEffect } from 'react';
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ServiceContentWrap from "./ServiceContentWrap";
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import ServicesData from "../../data/Services/services";
import Blog from "../../components/Blog";
import Thumbnail from "../../components/UI/Thumbnail";
import Content from "../../components/UI/Content";
import clientZona from '../../data/About/clientZona-Items';
import parse from "html-react-parser";

const ServiceDetails = (data) => {
    const serviceID = new URLSearchParams(window.location.search).get("id");
    const service = ServicesData.find((serviceItem) => serviceItem.id === parseInt(serviceID));
    const currentService = ServicesData.indexOf(service);
    let prevService;
    let nextService;
    currentService === 0 ? (prevService = ServicesData[currentService]) : (prevService = ServicesData[currentService - 1]);
    currentService + 1 === ServicesData.length
        ? (nextService = ServicesData[currentService])
        : (nextService = ServicesData[currentService + 1]);

    console.log("currentService", currentService);
    var url = window.location.href;
    const [numberid, setNumberId] = useState("");
    useEffect(() => {
        setNumberId(url.substring(url.lastIndexOf('=') + 1));
    }, [numberid]);

    return (
        <div>
            <PageHeader bgImg={require('../../assets/img/page-header.jpg')} title={service.title} content={service.shortDesc} />

            <PageWrapper classes="sm-top service-details-wrapper">
                <ServiceContentWrap service={service} totalService={ServicesData.length} nextService={nextService} prevService={prevService} />

                <Sidebar classes={'col-lg-4 mtn-40'}>
                    <SidebarItem title="Services">
                        <List classes="service-list">
                            {ServicesData.map((serviceItem) => (
                                <LI key={serviceItem.id}>
                                    <a href={`${process.env.PUBLIC_URL}/service/${serviceItem.title.split(' ').join('-').toLowerCase()}?id=${serviceItem.id}`}>
                                        {serviceItem.title}
                                    </a>
                                </LI>
                            ))}
                        </List>
                    </SidebarItem>
                </Sidebar>

                {numberid == 1 ? (
                    <div style={{ margin: "0 auto", paddingTop: "4%" }}>
                        <h2>Our Partners</h2>
                    </div>
                ) : (
                    ""
                )}

                {numberid == 1
                    ? clientZona.map((item) => (
                          <div className="about-area-wrapper sm-top" key={item.id}>
                              <div className="container">
                                  <div className="row align-items-lg-center">
                                      <div className="col-md-6 col-lg-5">
                                          <Thumbnail classes="about-thumb" imgSrc={require('../../assets/img' + item.thumb)} />
                                      </div>
                                      <div className="col-md-6 col-lg-7">
                                          <Content classes="about-content">
                                              <p>{parse(item.text)}</p>
                                          </Content>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      ))
                    : ""}
            </PageWrapper>
            {numberid != 1 ? <Blog numberOfPage={parseInt(numberid)} /> : ""}
        </div>
    );
};

export default ServiceDetails;
