import React from 'react';
import '../assets/css/MoreDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClinicMedical, faUserNurse, faBrain, faStethoscope, faChalkboardTeacher, faHome, faShieldAlt, faHouseUser, faHandcuffs, faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';

const ActionItem = ({ icon, label, link }) => {
    return (
      <a href={link} className="action-item">
        <div className="icon-circle">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
        <span className="label">{label}</span>
      </a>
    );
  };
  
  const MoreDetails = () => {
    return (
      <div>
        {/* <h2>AREA OF CARE</h2> */}
        <div className="actions-container">
          <ActionItem icon={faClinicMedical} label="PRIVATE HOSPITALS" />
          <ActionItem icon={faHouseUser} label="NURSING/RESIDENTIAL HOMES" />
          <ActionItem icon={faBrain} label="MENTAL HEALTH" />
          <ActionItem icon={faStethoscope} label="AHP/HSS" />
          <ActionItem icon={faChalkboardTeacher} label="SPECIALITY SCHOOLS" />
          <ActionItem icon={faHome} label="HOME CARE - COMPLEX CARE OF ADULTS AND CHILDREN" />
          <ActionItem icon={faHandcuffs} label="PRISONS AND OFFENDER HEALTHCARE" />
          <ActionItem icon={faHandHoldingMedical} label="ONE-TO-ONE CARE" />
          {/* Repeat for other items, replacing the icon, label, and link as necessary */}
        </div>
      </div>
    );
  };
  
  export default MoreDetails;
