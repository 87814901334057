import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography,Annotation } from "react-simple-maps";
import { Link } from 'react-router-dom'; // Import Link if needed for navigation
import '../../assets/css/map.css';
import JobTable from './JobTable';
import { geoCentroid } from 'd3-geo';

const Map = ({ jobs }) => {
  const width = 1500; // Adjust as needed
  const height = 1200; // Adjust as needed
  const scale = 10000; // Adjust for a closer view

  // State to manage displayed jobs based on the selected region
  const [displayedJobs, setDisplayedJobs] = useState([]);

  useEffect(() => {
    // Set all jobs to be displayed when the component mounts
    setDisplayedJobs(jobs);
  }, [jobs]);

  // Function to handle clicking on a region
  const handleRegionClick = (regionProperties) => {
    // Use the EER13CD property for filtering
    console.log("Clicked region properties:", regionProperties);
    const regionId = regionProperties.EER13CD;
    const jobsInRegion = jobs.filter(job => job.regionId === regionId);
    setDisplayedJobs(jobsInRegion);
};
  const getRandomBlue = () => {
    const shadesOfBlue = ['#ADD8E6', '#2589ff', '#87CEFA', '#00BFFF', '#1E90FF', '#4169E1'];
    return shadesOfBlue[Math.floor(Math.random() * shadesOfBlue.length)];
  };
  const showAllJobs = () => {
    setDisplayedJobs(jobs);
  };

  const renderRegionName = (name) => {
    const words = name.split(' ');
    return words.length > 1 ? (
      <>
        <tspan x="0" dy="-0.6em">{words[0]}</tspan>
        <tspan x="0" dy="1.2em">{words[1]}</tspan>
        <tspan x="0" dy="1.2em">{words[2]}</tspan>
        <tspan x="0" dy="1.2em">{words[3]}</tspan>
      </>
    ) : (
      <tspan x="0" dy="0.6em">{name}</tspan>
    );
  };


  return (
    <div>
      <h2 className="title">Job Openings</h2> {/* Added title */}
    <div className="map-and-table-container">
      <div className="map-container">
        <ComposableMap
          projectionConfig={{ scale: scale, center: [-2.0, 54.5] }}
          width={width}
          height={height}
          style={{ width: '100%', height: 'auto' }}
        >
          <Geographies geography={`${process.env.PUBLIC_URL}/Map.json`}>
            {({ geographies }) =>
              geographies.map(geo => (
                <React.Fragment key={geo.rsmKey}>
                  <Geography
                    geography={geo}
                    onClick={() => handleRegionClick(geo.properties)}
                    style={{
                      default: {
                        fill: getRandomBlue(),
                        outline: "none"
                      },
                      hover: {
                        fill: "#F53",
                        outline: "none"
                      },
                      pressed: {
                        fill: "#E42",
                        outline: "none"
                      }
                    }}
                  />
                  <Annotation
                    subject={geoCentroid(geo)}
                    dx={0}
                    dy={0}
                  >
                    <text x="0" y="0" fontSize={15} textAnchor="middle" fill="white" fontWeight="bold">
                      {renderRegionName(geo.properties.EER13NM)}
                    </text>
                  </Annotation>
                </React.Fragment>
              ))
            }
          </Geographies>
        </ComposableMap>
        
      </div>
      <JobTable displayedJobs={displayedJobs} />
    </div>
    <button className="show-all-jobs-button" onClick={showAllJobs}>Show All Jobs</button>
    </div>
  );
};

export default Map;
