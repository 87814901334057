import React from 'react';
import '../assets/css/CandidateActions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserNurse, faHospital, faPersonCirclePlus, faCirclePlus, faUserDoctor, faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';
import { faOldRepublic } from '@fortawesome/free-brands-svg-icons';

const ActionItem = ({ icon, label, link }) => {
    return (
      <a href={link} className="action-item">
        <div className="icon-circle">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
        <span className="label">{label}</span>
      </a>
    );
  };
  
  const NhsSectors = () => {
    return (
      <div className="actions-container">
        <ActionItem icon={faUserNurse} label="NHS ACUTE"/>
        <ActionItem icon={faHospital} label="NHS COMMUNITY CARE" />
        <ActionItem icon={faPersonCirclePlus} label="INSOURCING"/>
        <ActionItem icon={faUserDoctor} label="GENERAL PRACTICIONERS"/>
        <ActionItem icon={faHandHoldingMedical} label="HOSPICE"/>
      </div>
    );
  };
  
  export default NhsSectors;
