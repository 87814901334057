import React from 'react';
import '../assets/css/CandidateActions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPercent, faUserDoctor, faInfinity } from '@fortawesome/free-solid-svg-icons';

const ActionItem = ({ icon, label, link }) => {
    return (
      <a href={link} className="action-item">
        <div className="icon-circle">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
        <span className="label">{label}</span>
      </a>
    );
  };
  
  const IndustryComponent = () => {
    return (
      <div className="actions-container">
        <ActionItem icon={faInfinity} label="90+ HEALTHCARE SPECIALISTS HERE TO SUPPORT"/>
        <ActionItem icon={faClock} label="1M+ HOURS FILLED PER ANNUM"/>
        <ActionItem icon={faUserDoctor} label="3K+ ROLES PER MONTH, ON AVERAGE"/>
        <ActionItem icon={faPercent} label="95% TRUSTS WORKING WITH US REGULARLY"/>
      </div>
    );
  };
  
  export default IndustryComponent;
