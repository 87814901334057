import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTruckFast, faHeartPulse } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Info(props) {
  const iconStyle = {
    fontSize: '20px',
    color: 'white',
    marginLeft: '-7px',
    paddingLeft: '10px'
  };

  const iconStyleTwo = {
    fontSize: '20px',
    color: 'white',
    marginLeft: '-4px',
    paddingLeft: '10px'
  };

  const textStyle = {
    color: 'white',
    marginLeft: '6px',
    padding: '5px'
  };
  const textStyleTwo = {
    color: 'white',
    marginLeft: '6px',
    padding: '5px',
    paddingLeft: '6.8px'
  };

  return (
    <div className="text-left">
      <p style={{ color: 'white', marginBottom: '1px' }}>
        020 7139 5110 <br />
        info@phantomglobal.co.uk
      </p>
      <div>
        <Link to="/logistics">
          <FontAwesomeIcon icon={faTruckFast} style={iconStyle} />
          <span style={textStyle}>LOGISTICS</span>
        </Link>
        <Link to="/">
          <FontAwesomeIcon icon={faHeartPulse} style={iconStyleTwo} />
          <span style={textStyleTwo}>MEDICAL</span>
        </Link>
      </div>
    </div>
  );
}

export default Info;
