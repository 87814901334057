import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const JobTable = ({ displayedJobs }) => {
  // State for the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter jobs based on search query
  const filteredJobs = displayedJobs.filter(job =>
    job.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.regionName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="job-listings">
      {/* Search bar */}
      <input
        type="text"
        placeholder="Search for jobs..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-bar"
      />
        <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Region</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs.map((job, index) => (
            <tr key={index}>
              <td>
                <Link to={`/blog/${job.title.split(' ').join('-').toLowerCase()}?id=${job.id}`}>
                  {job.title}
                </Link>
              </td>
              <td>{job.regionName}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default JobTable;
