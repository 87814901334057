import React from 'react';
import SlickSlider from "../../components/UI/Slick";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../../components/UI/Thumbnail";
import Content from "../../components/UI/Content";
import aboutData from '../../data/About/home-one.json'


const ServiceContentWrap = ({ service, totalService, prevService, nextService }) => {
    return (
        <div className="col-lg-8">
            <div className="service-details-content">
                <div className="service-thumbnail-wrapper">
                    <SlickSlider settings={{
                        className: "service-details-thumb",
                        arrows: false,
                        dots: true
                    }}>
                        {
                            service.previewImages.map((previewImg, indx) => (
                                <div key={indx}>
                                    <img src={require('../../assets/img/' + previewImg)} alt={service.title} />
                                </div>
                            ))
                        }
                    </SlickSlider>
                </div>
            </div>

            <div className="service-details-info">
                <h3>{service.title}</h3>
                <p>{service.shortDesc}</p>
                <div className="about-service mt-50 mt-sm-35">
                    <h4>Why choose Phantom Global?</h4>
                    {
                        <div dangerouslySetInnerHTML={{ __html: service.aboutServiceDesc }} />
                    }
                    <List classes="service-feature mt-30">
                        {
                            service.features.map((feature, indx) => (
                                <LI key={indx}>{feature}</LI>
                            ))
                        }
                    </List>
                </div>
            </div>
        </div >
    );
};

export default ServiceContentWrap;