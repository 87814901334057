import React from 'react';
import OffCanvas from '../UI/OffCanvas';
import NavbarItemTwo from '../Header/Navbar/NavbarItemtwo';

const MobileMenuTwo = () => {
    // Specify the background color you want
    const backgroundColor = 'orange';

    return (
        <OffCanvas type="menu" backgroundColor={backgroundColor}>
            <div className="res-mobile-menu" style={{ backgroundColor }}>
                <div className="mobile-nav">
                    <NavbarItemTwo />
                </div>
            </div>
        </OffCanvas>
    );
};

export default MobileMenuTwo;
