import React from 'react';
import '../assets/css/TestimonialSection.css'; 

const fakeTestimonials = [
  {
    name: 'Irena F',
    status: 'Care Home Manager',
    testimonial: 'Phantom Global is the go-to healthcare recruitment agency for care homes. Their prompt response, rigorous screening, transparent pricing, and professionalism make them invaluable. I wholeheartedly recommend them for efficient and reliable temporary staffing solutions.',
    stars: 5
  },
  {
    name: 'Linda T',
    status: 'Operations Director',
    testimonial: 'Excel in temporary staffing, offering efficient solutions for Surgery doctors, General Practitioners, Band 5/6 RGN/RMN nurses, and HCAs on short notice.',
    stars: 5
  },
  {
    name: 'Chris U',
    status: 'HR Manager',
    testimonial: 'Regarding Clinics, this is a top-notch healthcare recruitment choice. Their personalized approach and efficiency make them ideal for temporary positions in Surgery, General Practice, nursing, and healthcare assistance. Highly recommended.',
    stars: 5
  },
  {
    name: 'Sarah D',
    status: 'Agency worker',
    testimonial: 'Consistent with shifts and very transparent, very attentive compliance officer and always pays on time (Agency Workers know what I’m talking about)',
    stars: 5
  },
];

const TestimonialSection = () => {
  return (
    <div className="testimonial-section">
      <h2>What people say about us</h2>
      <div className="testimonials-container">
        {fakeTestimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <div className="testimonial-content">
              <div className="testimonial-name">{testimonial.name}</div>
              <div className="testimonial-status">{testimonial.status}</div>
              <div className="testimonial-text">{testimonial.testimonial}</div>
              <div className="testimonial-stars">
                {'★'.repeat(testimonial.stars)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialSection;
