import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
import Testimonial from "../components/Testimonials/home-two";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Ofersindex from '../components/ofers/index';
import ServiceThumb from '../assets/img/about.jpg'
import Blog from "../components/Blog";

const PageService = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="OUR SERVICES"
                content="We help companies that do good look good."
            />
            <PageAbout
                title={'Our Services'}
                heading="What we specialise in:"
                thumb={ServiceThumb}

                content="<strong>NHS </strong> - Phantom Global specializes in recruiting healthcare professionals for the NHS, ensuring they meet all regulatory requirements and facilitating seamless onboarding. Their flexible staffing options and industry expertise contribute to the NHS's effective healthcare service delivery from bank staffing and last minute staffing solutions.<br /><br /> <strong>Private Healthcare </strong> - Phantom Global extends its healthcare staffing expertise to the private healthcare sector, helping private medical institutions secure qualified professionals. By offering tailored recruitment solutions and maintaining a pool of pre-screened candidates, Phantom Global supports the private healthcare industry in delivering top-quality care to patients. <br /><br /> <strong>Homecare </strong>  - Phantom Global is a versatile healthcare recruitment agency that caters to various sectors, including homecare. They specialize in connecting skilled caregivers with individuals and families seeking in-home assistance. By thoroughly vetting Support Workers, Healthcare Assistants and more for qualifications and compatibility; Phantom Global ensures that those in need of personalised homecare receive exceptional and tailored support, enhancing the quality of in-home healthcare services.
            

            <br /><br /> <strong> Permanent Staffing Solutions</strong>

            - We specialize in identifying and placing healthcare professionals in permanent positions within
            healthcare organizations. This includes hospitals, clinics, nursing homes, and other medical facilities.
            - Our extensive database comprises highly skilled physicians, nurses, therapists, and administrative
            staff, among others, to fill various roles within your organization.

            <br /><br /> <strong> Temporary and Locum Tenens Staffing</strong>
            - We understand that healthcare organizations often require temporary staff to cover short-term
            vacancies, seasonal peaks, or special projects.
            - Our agency excels in providing qualified temporary healthcare professionals, including locum
            tenens physicians and nurses, to ensure continuity of care.

            <br /><br /> <strong> Allied Health and Specialized Roles</strong>
            - Beyond traditional roles, we specialize in sourcing and placing allied health professionals, such as
            radiologists, laboratory technicians, pharmacists, and medical technologists.
            - We can also assist in recruiting for specialized positions, including nurse practitioners, physician
            assistants, and healthcare administrators.

            <br /><br /> <strong> Hospice</strong>
            - Phantom Global offers specialized healthcare recruitment services for the hospice sector in the UK. They focus on identifying and placing compassionate professionals who excel in providing end-of-life care and support. By carefully selecting and matching candidates with the specific needs of hospice settings, Phantom Global plays a vital role in enhancing the quality of care delivered during critical moments for patients and their families. Their expertise contributes significantly to the excellence of hospice care services across the UK.

            <br /><br /> <strong> Behavioral and Mental Health Staffing</strong>
            - We recognize the growing demand for mental health services and offer specialized recruitment
            for psychiatric nurses, counselors, therapists, and social workers.
            - Our agency helps healthcare organizations build dedicated teams to address mental health and
            behavioral needs.

            <br /><br /> <strong> Compliance and Regulation Expertise</strong>
            - Staying compliant with healthcare regulations is paramount. Phantom Global ensures that all
            candidates meet regulatory and credentialing requirements.
            - We provide ongoing support to keep both clients and candidates informed about the latest
            healthcare regulations and best practices.

            <br /><br /> <strong> Tailored Recruitment Strategies</strong>
            - We work closely with healthcare organizations to understand their unique culture, values, and
            staffing challenges.
            - Our team tailors recruitment strategies to ensure candidates not only possess the required skills
            but also align with the organizations mission and values.

            <br /><br /> <strong> Continuous Training and Development</strong>
            - Our commitment to excellence extends to the ongoing training and development of healthcare
            professionals.
            - We offer access to resources, courses, and updates to ensure that our candidates stay current
            with emerging best practices in the field.

            <br /><br /> <strong>  Client-Candidate Communication</strong>
            - Effective communication is vital. We maintain transparent and open lines of communication with
            both clients and candidates, ensuring that expectations are met and concerns are addressed
            promptly.

            <br /><br /> <strong>  Long-Term Partnerships</strong>
            - We aspire to be your long-term staffing partner, supporting your organizations growth and
            success in the healthcare industry.

            Phantom Global is dedicated to providing high-quality staffing solutions that empower healthcare
            organizations to deliver exceptional care to their patients. Our specialization in healthcare
            recruitment, compliance expertise, and commitment to ongoing excellence make us a trusted
            partner in the healthcare industry."
            />
            {/* <Blog /> */}
            <Services classes="sm-top" />
            {/* <PricingTable /> */}
            {/* <Testimonial /> */}
            {/* <BrandLogo /> */}
            {/* <Funfact classes="sp-top" /> */}
            <CallToAction />
            <Footer />
            <LoginRegister />
            <MobileMenu />
        </Fragment>
    );
};

export default PageService;