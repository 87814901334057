import React from 'react';
import From from "./From";
import ContactInfo from "./ContactInfo";

const ContactPage = ({ customBackgroundColor }) => {
    const colStyle = customBackgroundColor ? { backgroundColor: customBackgroundColor } : {};

    return (
        <div className={'contact-page-area-wrapper sp-y'}>
            <div className="container">
                <div className="contact-content-wrap">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-form-area contact-method">
                                <h3>Send us a Message</h3>
                                <From />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="contact-information contact-method" style={colStyle}>
                                <div className="contact-info-con">
                                    <h3>Let’s get in touch.</h3>
                                    <ContactInfo
                                        address="London<br/>Tel: 020 7139 5110 <br/> info@phantomglobal.co.uk"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
