import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from '../../assets/img/logo-dark.png'
import socialNetworks from '../../data/SocialNetworks/socials'

function FooterTwo() {
    const orangeTextStyle = { color: 'orange' };
    const linkStyle = {
        color: 'black',
        textDecoration: 'none',  // Optional: Remove underline on links
    };
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                {/* <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img src={Logo} alt="Logo" />
                                </Link> */}
                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} Phantom Global Agency Ltd. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                    <Widget title={<span style={orangeTextStyle}>Information</span>}>
                    <List classes="widget-list">
                                <LI>
                                    <Link
                                        to={`${process.env.PUBLIC_URL + "/logistics/about"}`}
                                        style={linkStyle}
                                        onMouseOver={(e) => e.target.style.color = 'orange'}
                                        onMouseOut={(e) => e.target.style.color = 'black'}
                                    >
                                        Our company
                                    </Link>
                                </LI>
                                <LI>
                                    <Link
                                        to={`${process.env.PUBLIC_URL + "/logistics/contact"}`}
                                        style={linkStyle}
                                        onMouseOver={(e) => e.target.style.color = 'orange'}
                                        onMouseOut={(e) => e.target.style.color = 'black'}
                                    >
                                        Contact us
                                    </Link>
                                </LI>
                                <LI>
                                    <Link
                                        to={`${process.env.PUBLIC_URL + "/logistics/services"}`}
                                        style={linkStyle}
                                        onMouseOver={(e) => e.target.style.color = 'orange'}
                                        onMouseOut={(e) => e.target.style.color = 'black'}
                                    >
                                        Our services
                                    </Link>
                                </LI>
                            </List>
                        </Widget>
                    </div>
                    <div className="col-md-4 col-lg-3">
                    <Widget title={<span style={orangeTextStyle}>Contact Us</span>}>
                            <address>
                                Web: phantomglobal.co.uk <br />
                                Email: info@phantomglobal.co.uk
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterTwo;