import React, { Component } from 'react';

function Message(props) {

    return (
        <div className="text-left">
            <p style={{ color: "white" }}>HEALTHCARE RECRUITMENT AGENCY</p>
        </div >

    )
}

export default Message;
