import React from 'react';
import { Link } from 'react-router-dom'

function BlogItem(props) {
    const blogURL = `/blog/${props.title.split(' ').join('-').toLocaleLowerCase()}?id=${props.id}`;

    console.log("proppss", props)
    return (
        <div className={props.cols ? props.cols : 'col-md-6 col-lg-4'}>
            <div className="blog-item">
                {
                    props.thumb ? (
                        <figure className="blog-thumb">
                            <Link to={`${process.env.PUBLIC_URL + blogURL}`}>
                                <img src={require('../../assets/img/' + props.thumb)} alt={props.title} />
                            </Link>
                        </figure>
                    ) : null
                }
                <Link to={`${process.env.PUBLIC_URL + blogURL}`}>
                    <div className="blog-content">
                        <h2 className="h5">{props.title}</h2>
                        <p class="text-dark">{props.excerpt}</p>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default BlogItem;