// SpecialismSection.js
import React from 'react';
import '../assets/css/SpecialismSection.css'; 
const specialisms = [
  {
    title: 'Nursing Homes',
    description: 'Providing compassionate and qualified nursing staff to create a supportive environment.',
  },
  {
    title: 'Private Hospitals',
    description: 'Specialist recruitment for private healthcare facilities demanding the highest standards.',
  },
  {
    title: 'Mental Health',
    description: 'Mental Health Services, supplying staff who are not only skilled but also have the empathy to handle sensitive roles.',
  },
  // ... Add more specialisms as needed
];

const SpecialismSection = () => {
  return (
    <div className="specialism-section">
      <h2>Our Specialisms</h2>
      <div className="specialisms-container">
        {specialisms.map((specialism, index) => (
          <div key={index} className="specialism">
            <h3>{specialism.title}</h3>
            <p>{specialism.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialismSection;
