import React, { useState } from 'react';
import parse from 'html-react-parser';
import CallToActionData from '../../data/CallToAction/call-to-action';
import { Link } from 'react-router-dom';

function CallToAction() {
    const [isHovered, setHovered] = useState(false);

    const buttonStyle = {
        color: isHovered ? 'white' : 'orange',
        border: '2px solid orange',
        backgroundColor: isHovered ? 'orange' : 'white',
        transition: 'color 0.3s, background-color 0.3s',
    };

    return (
        <div className="call-top-action-wrap sp-y">
            <div className="container">
                <div className="footer-top-content">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-lg-6">
                            <h2>{CallToActionData.title}</h2>
                            <p>{parse(CallToActionData.text)}</p>
                        </div>
                        <div className="col-md-4 col-lg-6 text-md-right mt-sm-25">
                            <Link
                                to="logistics/contact"
                                className="btn-outline"
                                style={buttonStyle}
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                            >
                                {CallToActionData.btnText}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CallToAction;
