import React, { Fragment } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import LandingGrid from '../components/LandingGrid';


const Landing = () => {
    return (
        <Fragment>
            <LandingGrid/>
            <MobileMenu />
        </Fragment>
    );
};

export default Landing;