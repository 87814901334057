import React from 'react';
import SectionTitle from '../UI/SectionTitle'
import ServiceItem from './ServiceItem'
import ServicesData from '../../data/Services/services'


import serviceTopBg from '../../assets/img/fun-fact-bg.jpg'

function Services({ classes }) {

    return (
        <div className={`service-area-wrapper ${classes}`}>
            <div className="service-area-top" style={{ backgroundImage: `url("${serviceTopBg}")` }} >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-5 m-auto text-center">
                            <SectionTitle variant="light" title="Our Services" heading="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="service-content-area">
                <div className="container">
                    <div className="row justify-content-center">
                        {
                            ServicesData.map(service => (
                                <ServiceItem key={service.id} id={service.id} title={service.title} text={service.shortDesc} thumb={service.thumb} />
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* <div style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",

            }}>
                <img style={{
                    maxWidth: "500px"
                }} className='p-5' src={require('../../assets/img/req.jpeg')} />

                <img style={{
                    maxWidth: "300px"
                }} className='p-5' src={require('../../assets/img/home.jpeg')} />
            </div> */}
        </div>
    );
}

export default Services;