import React from 'react';
import closeIcon from '../../../assets/img/icons/cancel.png'

const OffCanvas = ({type,backgroundColor, children}) => {

    const LoginRegClose = () => {
        const canvasWrapper = document.querySelector('.off-canvas-cog');
        canvasWrapper.classList.remove('active');
        document.querySelector('body').classList.remove('fix');
    }

    const MobileMenuClose = () => {
        const canvasWrapper = document.querySelector('.off-canvas-menu');
        canvasWrapper.classList.remove('active');
        document.querySelector('body').classList.remove('fix');
    }

    const closeCanvas = () => {
        const canvasWrapper = document.querySelector(`.off-canvas-${type}`);
        canvasWrapper.classList.remove('active');
        document.querySelector('body').classList.remove('fix');
    };

    return (
        <aside className={`off-canvas-wrapper off-canvas-${type}`}>
            <div onClick={closeCanvas} className="off-canvas-overlay" style={{ backgroundColor }} />
            <div className="off-canvas-inner">
                <div className="close-btn">
                    <button onClick={closeCanvas} className="btn-close">
                        <img src={closeIcon} alt="Close" />
                    </button>
                </div>

                <div className="off-canvas-content mb-sm-30" style={{ backgroundColor }}>
                    {children}
                </div>
            </div>
        </aside>
    );
};

export default OffCanvas;