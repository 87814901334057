import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import Navbar from './Navbar/Navbar';
import HeaderConfig from './HeaderConfig';
import Info from './info';
import Message from './message';

const Header = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [isResponsive, setIsResponsive] = useState(false);

    const checkScreenWidth = () => {
        setIsResponsive(window.innerWidth <= 992); // Adjust the breakpoint as needed
    };

    const sticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? setIsSticky(true) : setIsSticky(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', sticky);
        window.addEventListener('resize', checkScreenWidth);

        // Initial check
        checkScreenWidth();

        return () => {
            window.removeEventListener('scroll', sticky);
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    return (
        <header className={`header-area ${isSticky ? 'sticky' : 'not-sticky'}`}>
            <div className="container">
                <div className="row align-items-center">
                <div className="d-none d-lg-block">
                        <Info />
                    </div>
                    {isResponsive ? (
                        <div className="col-5 col-lg-2 mt-3 logloPadding">
                            <Info />
                        </div>
                    ) : (
                        <div className="col-5 col-lg-2 mt-3 logloPadding">
                            <Logo />
                        </div>
                    )}


                    <div className="d-none d-lg-block" style={{
                        position: "absolute", right: "10%",
                    }}
                    >
                        <div className="navigation-area">
                            <Navbar />
                        </div>
                    </div>

                    <div className="col-7 col-lg-3 meny_mobile">
                        <HeaderConfig />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
