import React, { Fragment } from 'react';
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
import Testimonial from "../components/Testimonials/home-two";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Ofersindex from '../components/ofers/index';
import ServiceThumb from '../assets/img/truck.jpg'
import Blog from "../components/Blog";
import HeaderTwo from '../components/Header/indextwo';
import FooterTwo from '../components/Footer/indextwo';
import CallToActionTwo from '../components/CallToAction/indextwo';
import MobileMenuTwo from '../components/MobileMenu/indextwo';

const ServiceTwo = () => {
    return (
        <Fragment>
            <HeaderTwo/>
            <PageHeader
                bgImg={require('../assets/img/servicestwo.jpg')}
                title="OUR SERVICES"
                content="We help companies that do good look good."
            />
            <PageAbout
                title={'Our Services'}
                heading="What we specialise in:"
                thumb={ServiceThumb}

                content="<strong> Reliable Service </strong> - Phantom Global stands out as a top-tier recruitment agency, providing opportunities in Driving, Warehousing, and Logistics roles across the UK. Our team is renowned for its robust work ethic, dedication, and sincere passion for achieving outstanding results. We take pride in our industry expertise, built over many years, enabling us to adeptly connect qualified job seekers with employers swiftly and efficiently.
            


            <br /><br /> <strong> Clients </strong>
            - We deliver a specialised recruitment service to employers within the driving, warehousing, and logistics sector across the UK. If you have a position to fill, we would be delighted to hear from you. Our dedication revolves around sourcing top-tier candidates for both temporary and permanent roles.
            

            <br /><br /> <strong> Tailored Recruitment Strategies</strong>
            - We work closely with logistics organizations to understand their unique culture, values, and
            staffing challenges. Our team tailors recruitment strategies to ensure candidates not only possess the required skills
            but also align with the organizations mission and values.

            <br /><br /> <strong> Candidate Registration</strong>
            - We continually seek out high-caliber and dependable candidates. If you or someone you know is interested in learning about exciting new opportunities, kindly fill out the form provided. Your input is highly valued, and we look forward to connecting with you!
            

            <br /><br /> <strong>  Client-Candidate Communication</strong>
            - Effective communication is vital. We maintain transparent and open lines of communication with
            both clients and candidates, ensuring that expectations are met and concerns are addressed
            promptly.

            <br /><br /> <strong>  Long-Term Partnerships</strong>
            - We aspire to be your long-term staffing partner, supporting your organizations growth and
            success in the logistics industry.

            Phantom Global is dedicated to providing high-quality staffing solutions that empower logistics
            organizations to deliver exceptional services. Our specialization in logistics
            recruitment, and the commitment to ongoing excellence make us a trusted
            partner in the logistics industry."
            />
            <Funfact ribbonColor="#ffa500" />
            <CallToActionTwo />
            <FooterTwo />
            <LoginRegister />
            <MobileMenuTwo />
        </Fragment>
    );
};

export default ServiceTwo;