import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from "../components/PageWrapper";
import BlogDetailsContentWrapper from "./BlogDetails/ContentWrapper";
import SidebarForBlog from "./SidebarForBlog";
import PageHeader from "../components/PageHeader";
import IndustryData from "../data/Industry/industry"; // Ensure this path is correct
import CandidateActions from '../components/IndustryComponent';
import IndustryComponent from '../components/IndustryComponent';

const IndustryDetailsPage = ({ sidebar }) => {
    const { industryType } = useParams(); // Use useParams to get the industryType
    const post = IndustryData.find(post => post.type === industryType); // Find post by type, not id

    if (!post) {
        return <div>Post not found</div>;
    }

    const backgroundImages = {
        nurses: require('../assets/img/nurse.jpg'),
        doctors: require('../assets/img/doctor.jpg'),
        'GP': require('../assets/img/gp.jpg'),
        'ahp-hss': require('../assets/img/ahp.jpg'),
        // Add more types and corresponding background images as needed
    };

    // Select the background image based on post type
    const bgImg = backgroundImages[post.type];

    return (
        <Fragment>
            <PageHeader
                bgImg={bgImg}
                title={post.title}
                content={post.excerpt}
            />
            <IndustryComponent/>
            <PageWrapper classes={'blog-details-page-content sp-y'}>
                <div className={!sidebar ? 'col-12' : 'col-lg-9'}>
                    <BlogDetailsContentWrapper sidebar={sidebar} post={post} />
                </div>
                {sidebar ? <SidebarForBlog /> : null}
            </PageWrapper>
        </Fragment>
    );
};

export default IndustryDetailsPage;
