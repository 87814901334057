import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Services from "../components/Services";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Features from "../components/Features";
import Testimonial from "../components/Testimonials/home-two";
import Team from "../components/Team/home-two";
import SpecialismSection from "../components/SpecialismSection";
import MoreDetails from "../components/MoreDetails";

import ServiceThumb from '../assets/img/about.jpg'
import { color } from '@mui/system';
import HeaderTwo from '../components/Header/indextwo';
import MobileMenuTwo from '../components/MobileMenu/indextwo';
import FooterTwo from '../components/Footer/indextwo';
import CallToActionTwo from '../components/CallToAction/indextwo';
import AboutTwoPage from '../components/About/about-two/indexpage';

const PageAbout = () => {
    const customAboutText = {
        // Custom values if needed
        "title": "ABOUT US",
        "heading": "About Us",
        "since": "Since 2023",
        "text": "<p>Phantom Global has firmly established itself as a premier recruitment agency, distinguished for its excellence in facilitating unparalleled career opportunities within the realms of Driving, Warehousing, and Logistics across the United Kingdom. Our team is widely recognized for embodying a formidable work ethic, unwavering dedication, and a genuine passion for consistently delivering exceptional results.</p><p>At the heart of our success lies a profound commitment to industry expertise cultivated over numerous years. This wealth of experience equips us with the proficiency to seamlessly connect highly qualified job seekers with employers, ensuring a swift and efficient matchmaking process. We take immense pride in our ability to navigate the intricate landscape of the job market, leveraging our insights and connections to create symbiotic relationships between talent and organizations.</p><p>Our mission extends beyond merely filling vacancies; we are dedicated to fostering long-term partnerships that contribute to the growth and success of both individuals and businesses. Phantom Global remains steadfast in its pursuit of excellence, continuously evolving to meet the dynamic demands of the ever-changing job market. With a steadfast focus on quality, integrity, and client satisfaction, we are not just a recruitment agency but a strategic partner in the journey toward professional fulfillment and organizational success.</p><p>Join us at Phantom Global, where opportunities meet exceptional talent, and together, we build a future of achievement and prosperity.</p>",
        "btnText": "Contact us",
        "btnLink": "/contact",
        "thumb": "/about-3.jpg"
    };
    return (
        <Fragment>
            <HeaderTwo/>
            <PageHeader
                bgImg={require('../assets/img/lggg.jpg')}
                title="ABOUT US"
                content=""
            />
            <AboutTwoPage customAbout={customAboutText} />
            <Features classes={'sm-top'} />
            <Funfact ribbonColor="#ffa500" />
            <CallToActionTwo/>
            <FooterTwo/>
            <LoginRegister />
            <MobileMenuTwo />
        </Fragment>
    );
};

export default PageAbout;