import React from 'react';
import NavbarItemTwo from './NavbarItemtwo';

function NavbarTwo(props) {
    return (
        <ul className="main-menu nav">
            <NavbarItemTwo />
        </ul>
    );
}

export default NavbarTwo;